<template>
  <div class="content-main-right" style="position:relative; width: 100%; height: 100%;">
    <div v-if="debug">mapData:{{mapData}}</div>
    <div style="height: 0; display: inline; overflow: hidden" v-if="!mapData.isShow">
      <div
        class="el-input el-input--mini el-input--suffix"
        style="display: flex;height:0; background:#eee;justify-content: space-between"
      >
        <div style=" display: flex;height: 28px; width: 30%">
          <input
          id="tipinput"
          type="text"
          autocomplete="off"
          class="el-input__inner"
          placeholder="请输入地址"
          maxlength="40"
       
          v-model="searchText"
        />
        <el-button type="success" class="btn-success-hw" @click="onSearch">查询</el-button>
        </div>
        <el-button v-if="enableDraw" class="btn-hw" @click="onClear">清除所有覆盖物</el-button>
      </div>
    </div>
    <div id="container" style="width: 100%; height: 100%; border:1px solid #607D8B"></div>
    <div id="panel"></div>
    <div id="buttons" v-if="enableDraw">
      <el-tooltip class="item" effect="dark" placement="bottom">
        <div slot="content">点击可删除绘制的图形</div>
        <el-button type="danger" icon="el-icon-delete" circle @click="onDelete"></el-button>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" placement="bottom">
        <div slot="content">
          提 示：
          <br />1. 点本按钮后，在地图上多次点击鼠标绘图；
          <br />2. 双击或右键单击结束绘图；
          <br />3. 拖动所绘制图形上的小圆点可调整图形范围；
          <br />4. 点击所绘制图形之外的区域结束编辑；
          <br />5. 再次双击所绘制图形之后，可编辑。
        </div>
        <el-button type="primary" icon="el-icon-edit" circle @click="onCreate"></el-button>
      </el-tooltip>
    </div>
    <slot></slot>
  </div>
</template>

<script>
/**
 * 本地图组件主要信息含：
 * 1个多边形，
 * 1个围栏范围。
 */

export default {
  name: "ayl-map-polygon",

  props: {
    //地图数据
    value: {
      type: Object,
      default() {
        return {
          polygonScope: null, //（1个）多边形具体的点，形如[[118,36],[117,32],[116,113]]
          polygonArea: 0, //（1个）多边形的面积
          scope: null, //围栏范围，经纬度点阵数组，形如[[118,36],[117,32],[116,113]]
          move2center: true //是否居中显示
        };
      }
    },
    //背景多边形线颜色
    strokeColor: {
      type: String,
      default: "#ff0000"
    },
    //背景多边形填充色
    fillColor: {
      type: String,
      default: "#42B983"
    },
    //多边形线颜色
    strokeColor2: {
      type: String,
      default: "#1b34df"
    },
    //多边形填充色
    fillColor2: {
      type: String,
      default: "#42B983"
    },
    //是否允许绘制
    enableDraw: {
      type: Boolean,
      default: true
    },
    //精度:小数点后的位数
    accuracy: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      debug: 0,
      mapData: this.value,
      searchText: "",
      map: null, //高德插件
      auto: null, //高德插件
      placeSearch: null, //高德插件
      polyEditor: null, //高德polyEditor插件
      polygon: null, //高德多边形-背景范围
      polygon2: null //新绘的多边形
    };
  },

  methods: {
    initAmap() {
      let self = this;
      // eslint-disable-next-line no-undef
      self.map = new AMap.Map("container", {
        center: self.mapData.center,
        resizeEnable: true,
        zoom: 11
      });
      self.map.plugin(
        [
          "AMap.ToolBar",
          "AMap.MouseTool",
          "AMap.Autocomplete",
          "AMap.PlaceSearch",
          "AMap.PolyEditor"
        ],
        function() {
          self.toolBar = new AMap.ToolBar({
            position: "RT",
            ruler: false,
            locate: true
          });
          self.map.addControl(self.toolBar);

          self.auto = new AMap.Autocomplete({
            input: "tipinput"
          });

          self.placeSearch = new AMap.PlaceSearch({
            pageSize: 5, // 单页显示结果条数
            pageIndex: 1, // 页码
            // city: "010", // 兴趣点城市
            citylimit: true, //是否强制限制在设置的城市内搜索
            map: self.map, // 展现结果的地图实例
            panel: "panel", // 结果列表将在此容器中进行展示。
            autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
          });

          AMap.event.addListener(self.auto, "complete", function(ev) {
            document.querySelector("#panel").innerHTML = "";
          });
          AMap.event.addListener(self.auto, "select", function(ev) {
            self.placeSearch.search(ev.poi.name); //关键字查询查询
          });
          AMap.event.addListener(self.placeSearch, "selectChanged", function(
            ev
          ) {
            let lnglat = ev.selected.marker.getPosition();
            self.mapData.lnglat = [lnglat.lng, lnglat.lat];
            // console.log(lnglat.lng,lnglat.lat);
          });
          AMap.event.addListener(self.placeSearch, "markerClick", function(ev) {
            let lnglat = ev.marker.getPosition();
            // console.log(lnglat.lng,lnglat.lat);
            self.mapData.lnglat = [lnglat.lng, lnglat.lat];
          });

          //鼠标点击多边形之外的区域结束编辑
          self.map.on("click", function(ev) {
            if (self.polyEditor) self.polyEditor.close();
          });
        }
      );
    },
    onSearch() {
      if (this.searchText && this.searchText.trim()) {
        this.placeSearch.search(this.searchText.trim());
      }
    },
    onCreate() {
      let self = this;
      if (!self.enableDraw) return;
      if (self.mouseTool == null) self.mouseTool = new AMap.MouseTool(self.map);
      self.mouseTool.polygon({
        strokeColor: self.strokeColor2,
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: self.fillColor2,
        fillOpacity: 0.4,
        // 线样式还支持 'dashed'
        strokeStyle: "solid"
        // strokeStyle是dashed时有效
        // strokeDasharray: [30,10],
      });
      self.mouseTool.on("draw", function(event) {
        // event.obj 为绘制出来的覆盖物对象
        // self.log("覆盖物对象绘制完成", event.obj.getPath());
        self.mapData.polygonScope = event.obj.getPath().map(item => {
          return [item.lng, item.lat];
        });
        self.log("覆盖物对象绘制完成", self.mapData.polygonScope);
        self.mouseTool.close(true);
      });
    },
    onDelete() {
      this.mapData.polygonScope = null;
    },
    onClear() {
      let self = this;
      self.mapData.polygonScope = null;
      self.map.clearMap();
      document.querySelector("#panel").innerHTML = "";
    }
  },

  watch: {
    mapData: {
      deep: true,
      handler: function(v, ov) {
        let self = this;

        //因为self.mapData是个引用类型，所以不用强制赋值。
        //但是似乎不符合vue的规范。有待商榷。
        // self.mapData = v;

        self.$nextTick(); //因为地图可能不会刷新，所以需要执行$nextTick()

        //回显绘制围栏范围（多边形）
        if (self.polygon) {
          self.polygon.setMap(null);
          self.polygon = null;
        }
        if (self.mapData.scope) {
          self.polygon = new AMap.Polygon({
            path: self.getScopeData, //设置多边形边界路径
            strokeColor: self.mapData.strokeColor || self.strokeColor, //线颜色
            strokeWeight: 2, //线宽
            strokeOpacity: 1, //线透明度
            fillOpacity: 0.4, //填充透明度
            fillColor: self.mapData.fillColor || self.fillColor, //填充色
            bubble: true,
            zIndex: 1
          });
          self.map.add(self.polygon);
        }

        // 缩放地图到合适的视野级别
        if (self.mapData.move2center) {
          !self.mapData.scope &&
            self.polygon2 &&
            self.map.setFitView(self.polygon2);
          self.mapData.scope &&
            self.polygon &&
            self.map.setFitView(self.polygon);
          self.mapData.move2center = false;
        }

        //回显绘制多边形
        if (self.polygon2) {
          self.polygon2.setMap(null);
          self.polygon2 = null;
        }
        if (self.polyEditor) {
          self.polyEditor.close();
          self.polyEditor.off("adjust", () => {});
          self.polyEditor = null;
        }
        if (self.mapData.polygonScope) {
          self.polygon2 = new AMap.Polygon({
            path: self.getPolygonScopeData, //设置多边形边界路径
            strokeColor: self.mapData.strokeColor2 || self.strokeColor2, //线颜色
            strokeWeight: 2, //线宽
            strokeOpacity: 1, //线透明度
            fillOpacity: 0.4, //填充透明度
            fillColor: self.mapData.fillColor2 || self.fillColor2, //填充色
            bubble: false,
            zIndex: 10
          });
          // 计算多边形的面积
          self.mapData.polygonArea = Number(
            AMap.GeometryUtil.ringArea(self.mapData.polygonScope)
          ).toFixed(self.accuracy);
          self.$emit("change", self.mapData);
          self.polygon2.on("dblclick", () => {
            self.enableDraw && self.polyEditor && self.polyEditor.open();
          });
          // self.polyEditor.setTarget(self.polygon2);

          self.polyEditor = new AMap.PolyEditor(self.map, self.polygon2);
          //开启编辑多边形
          if (self.enableDraw) self.polyEditor.open();
          //编辑时自动更新多边形的path数据
          self.polyEditor.on("adjust", event => {
            self.mapData.polygonScope = event.target.getPath().map(item => {
              return [item.lng, item.lat];
            });
            self.log("拖动了多边形的小圆点", self.mapData.polygonScope);
          });

          self.map.add(self.polygon2);
        }
      }
    }
  },

  computed: {
    getMapData: {
      get() {
        return this.mapData;
      }
    },
    //因为高德地图会“原地”修改数据，所以必须深拷贝
    getScopeData: {
      get() {
        return this.$_.cloneDeep(this.mapData.scope);
      }
    },
    //因为高德地图会“原地”修改数据，所以必须深拷贝
    getPolygonScopeData: {
      get() {
        return this.$_.cloneDeep(this.mapData.polygonScope);
      }
    }
  },

  mounted() {
    this.initAmap();
  }
};
</script>

<style scoped>
#panel {
  width: 40%;
  position: absolute;
  top: 0px;
  left: 0;
}
#buttons {
  position: absolute;
  top: 20px;
  right: 0;
  margin-right: 70px;
}
</style>
